@font-face {
    font-family: 'CHANEWEI';
    src: url('/fonts/CHANEWEI.eot');
    src: url('/fonts/CHANEWEI.eot') format('embedded-opentype'),
    url('/fonts/CHANEWEI.ttf') format('ttf'),
    url('/fonts/CHANEWEI.woff') format('woff'),
    url('/fonts/CHANEWEI.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Oswald';
    src: local('Oswald'), url('/fonts/oswald/Oswald-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('/fonts/lato/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

#root {
    justify-content: space-between;
    align-items: unset;
    display: flex;
    min-height: 100vh; 
    flex-direction: column
}